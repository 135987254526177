import React from 'react'
import { useUserLandingPageContext } from "../../contexts/userLandingPageContext"
import BreadCrumbs from "./breadCrumbs"
import SideBar from "./sidebar"
import Widget from "../shared/widget"
import Details from "./details"

const Display = () => {
  const { user, selectedWidget } = useUserLandingPageContext()

  return (
    <>
      {/* 98 px height combined */}
      <BreadCrumbs />
      <div className='pl-8 pb-5'>
        <h1 className='text-3xl font-bold'>Welcome {user?.human_identifier}</h1>
      </div>
      <div className='h-[calc(100%-98px)] flex flex-col md:flex-row gap-8'>
        <SideBar containerClasses='md:w-1/4 grow-0' />
        <div className='flex flex-col gap-8 grow h-full overflow-y-scroll'>
          <Widget widgetProp={selectedWidget} widgetStyles="" buttonName={'Widget'} />
          <div className='flex flex-row justify-between'>
            <Details user={user} containerStyles="my-8 mx-4" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Display
