import { faSync } from "@fortawesome/free-solid-svg-icons/faSync"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from 'react'
import { useNotesApiContext, useNotesContext } from "../../contexts/notesContext"

const SyncNotesButton = () => {
  const { syncEnabled, awaitingJobs, routes, project } = useNotesContext()
  const { syncNotes, syncMobileRecords } = useNotesApiContext()

  const onClick = () => {
    if (project.mobile_data_provider === 'terrago_gen_4') {
      syncNotes(routes.syncNotesPath)
    } else {
      syncMobileRecords(project.id)
    }
  }

  if (awaitingJobs) {
    return (
      <div className='flex flex-row items-center rounded-2xl px-5 py-1'>
        <FontAwesomeIcon icon={faSync} spin className='text-indigo-500' />
        <p className='ml-3 -mr-2'>Pulling Notes</p>
      </div>
    )
  } else {
    return (
      <button
        className={'btn ' + (syncEnabled ? 'btn-primary cursor-pointer' : 'btn-disabled cursor-not-allowed')}
        disabled={!syncEnabled}
        onClick={onClick}>
        <FontAwesomeIcon icon={faSync} className='mr-2' />
        Pull notes
      </button>
    )
  }
}

export default SyncNotesButton
