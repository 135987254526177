import { simpleGet, simplePost } from "../apiConfig"

export const importProjectMobileRecords = (projectId) => {
  return simplePost('/sync_mobile_records', { project_id: projectId })
}

export const importQfieldMobileRecords = (projectId) => {
  return simplePost('/sync_qfield', { project_id: projectId })
}

export const getProjectMobileRecords = (projectId) => {
  return simpleGet(`/projects/${projectId}/mobile-record.json`)
}

export const getRecord = (projectId, recordId) => {
  return simpleGet(`/projects/${projectId}/mobile-record/${recordId}.json`)
}
