import React, { useEffect } from "react";
import { useTimesheetContext } from "../../../../../contexts/timesheetContext";
import { useTimeTableApi, useTimeTableContext } from "../../../../../contexts/timeTableContext";
import { useTimesheetEntry } from "../../../../../hooks/useTimesheetEntry";
import { getDateFromWeekStartAndDay } from "../../../../../utilities/dateHelpers";

import SelectField from "../../selectedField";
import TimeHiddenFields from "./hiddenTimeFields";
import TimesheetModalHourInput from "./hourInput";

const TimeEntryForm = ({ timeEntryFormRef }) => {
  const { updateEditEntryState } = useTimeTableApi()
  const { userId, editEntry, week } = useTimeTableContext()
  const { projects, portfolios, tasks, weekStart } = useTimesheetContext()
  const { id, description, errors, hours, selectedDay } = editEntry
  const {
    portfolioOptions, jobNumberOptions, projectOptions, historicalJobNumberOptions, taskOptions,
    portfolioValue, jobNumberValue, projectValue, historicalJobNumberValue, taskValue,
    updateField, updateProjectId, dayOptions
  } = useTimesheetEntry({ userId, editEntry, projects, portfolios, tasks, weekStart, updateEditEntryState })

  useEffect(() => {
    if (selectedDay) {
      updateField("date", getDateFromWeekStartAndDay(weekStart, selectedDay))
      if (week[selectedDay]) {
        updateField("day_entry_id", week[selectedDay].id)
      }
    }
  }, [selectedDay, weekStart, updateField])

  return (

    <form ref={timeEntryFormRef} className="flex flex-col gap-5">
      <TimeHiddenFields {...editEntry} userId={userId} />

      {/* Project Information */}
      <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
        <SelectField label="Job Number" name="job_number" options={jobNumberOptions} value={jobNumberValue}
                     onChange={updateProjectId} required errors={errors} />
        <SelectField label="Historical Job Number" name="historical_job_number" options={historicalJobNumberOptions}
                     value={historicalJobNumberValue} onChange={updateProjectId} />
      </div>
      <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
        <SelectField label="Portfolio" name="portfolio_id" options={portfolioOptions} value={portfolioValue}
                     onChange={event => updateField("portfolio_id", event?.value)} />
        <SelectField label="Project" name="project_id" options={projectOptions} value={projectValue}
                     onChange={updateProjectId} required errors={errors} />
      </div>

      {/* Task Selection */}
      <input type="hidden" name="timesheet_task_id" value={taskValue?.value} disabled={!!!id} />
      <SelectField label="Task" options={taskOptions} name="timesheet_task_id" value={taskValue}
                   onChange={(option) => updateField("timesheet_task_id", option?.value || null)} required
                   errors={errors} disable={!!id} />

      {/* Hours/Days */}
      <a className="text-red-700 text-sm">*Only one day per entry</a>
      {(!selectedDay || !id) && (
        <SelectField
          label="Select Day"
          options={dayOptions}
          onChange={(selectedOption) => updateField("selectedDay", selectedOption ? selectedOption.value : null)}
          required errors={errors} />
      )}
      {selectedDay && (
        <TimesheetModalHourInput name={`${selectedDay.charAt(0).toUpperCase() + selectedDay.slice(1)} hours`}
                                 field="hours" value={hours || ""} required errors={errors} />
      )}

      {/* Description */}
      <label htmlFor="description">
        <h2>Description</h2>
        <textarea
          className="border-solid border-2 border-gray-300 rounded-lg h-full min-h-fit w-full relative p-3"
          placeholder="Enter description..."
          onChange={event => updateField("description", event.target.value)}
          value={description || ""}
          name="description"
        />
      </label>

    </form>
  )
}

export default TimeEntryForm
