export const getBoreholeId = (mobileRecord) => {
  const boreholeEntry = mobileRecord.entries.find(entry => entry.name === "boreholeId")
  const boreholeId = boreholeEntry ? boreholeEntry.value : undefined

  return boreholeId
}

export const updateEntryHelper = (mobileRecord, descendantRecords, entry, map) => {

  if (map.length === 1) {
    const updatedEntries = mobileRecord.entries.map(e => e.id === entry.id ? entry : e)
    return { updatedRecord: { ...mobileRecord, entries: updatedEntries }, updatedDescendantRecords: descendantRecords };
  }
  if (map.length >= 2) {
    const updatedDescendantRecords = JSON.parse(JSON.stringify(descendantRecords))

    const recordToUpdate = map.reduce((acc, uuid) => acc[uuid], updatedDescendantRecords)

    const updatedEntries = recordToUpdate.entries.map(e => e.id === entry.id ? entry : e)
    recordToUpdate.entries = updatedEntries

    return {
      updatedRecord: mobileRecord,
      updatedDescendantRecords
    }

  }
}

export const recordEntryTableHeaders = formTemplate => formTemplate.fields.map(field => field.label)

export const separateChildRecords = (childRecords) => {
  const { sampleDetails, groupedRecords } = childRecords.reduce(
    (acc, record) => {
      if (record.form_record_type === "Sample_Details") {
        acc.sampleDetails.push(record) // Add to sampleDetails
      } else {
        (acc.groupedRecords[record.record_type] ??= []).push(record) // Group other records by type
      }
      return acc;
    },
    { sampleDetails: [], groupedRecords: {} } // Initialize with separate objects
  )

  return { sampleDetails, groupedRecords }
}

// I would like to make a hash of hashes of the records with the parent_uuid as key and the record with its uuid as a key for the value
export const mapDescendantRecords = (descendantRecords) => {
  return descendantRecords.reduce((acc, record) => {
    const { parent_uuid: parentUuid, uuid: uuid } = record

    return { ...acc, [parentUuid]: { ...acc[parentUuid], [uuid]: record, }, }
  }, {})
}
