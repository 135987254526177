import { isEmpty } from "lodash"
import { createExport, getExport } from "../api/cocApi"
import { getSamples } from "../api/samplesApi"

export const getExportFile = (exportPath, exportFormat, includeChangeOfControl, headerDetails, selectedSamples, stats, draftStatus = false) => {
  const filteredSamples = selectedSamples.filter(sample => sample.selected)

  const payload = {
    draft_status: draftStatus,
    coc_format: exportFormat ? exportFormat : 'pdf',
    header_data: headerDetails,
    include_coc: includeChangeOfControl,
    stats: stats,
    coc_export_samples_attributes: filteredSamples.map(item => {
      return {
        sample_id: Object.keys(item)[0],
        coc_export_sample_chemicals_attributes: item?.chemicalNames?.map(chemicalName => {
          return { chemical_name: chemicalName }
        })
      }
    }
    )
  }

  return createExport(exportPath, payload).then(res => {
    if (!draftStatus) {
      window.open(res.data.coc_export_file_path, '_blank', 'noopener,noreferrer')
    }
  })
}

export const updateSample = (sampleId, hsv, ssv, selectedChemicals, selectedSamples, setSelectedSamples) => {
  const selectedSamplesCopy = [...selectedSamples]
  const sampleToModify = selectedSamplesCopy.find(obj => Object.keys(obj)[0] === sampleId)
  if (sampleToModify) {
    sampleToModify.hsv = hsv
    sampleToModify.ssv = ssv
    sampleToModify.chemicalNames = selectedChemicals
    setSelectedSamples(selectedSamplesCopy)
  }
}

export const toggleSample = (sampleId, selectedSamples, setSelectedSamples) => {
  const selectedSamplesCopy = [...selectedSamples]
  const sampleToModify = (selectedSamplesCopy.find(obj => Object.keys(obj)[0] === sampleId))

  sampleToModify.selected = !(sampleToModify.selected)
  setSelectedSamples(selectedSamplesCopy)
}

export const selectAll = (selectedSamples) => {
  const selectedSamplesCopy = [...selectedSamples]
  selectedSamplesCopy.forEach(sample => sample.selected = true)
  return selectedSamplesCopy
}

export const deselectAll = (selectedSamples) => {
  const selectedSamplesCopy = [...selectedSamples]
  selectedSamplesCopy.forEach(sample => sample.selected = false)
  return selectedSamplesCopy
}

export const toggleChemical = (chemical, selectedChemicals, setSelectedChemicals) => {
  if (selectedChemicals.includes(chemical.name)) {
    const index = selectedChemicals.indexOf(chemical)
    selectedChemicals.splice(index, 1)
  } else {
    const newChemicals = selectedChemicals.filter(item => item !== chemical)
    setSelectedChemicals([...newChemicals, chemical])
  }
}

export const getExportDetails = (currentExportID, project, setLoading, setSelectedSamples, setSelectedChemicals, updateHeaderDetails, allSamples) => {
  getExport(project, currentExportID)
    .then(getExportRes => {
      const getExportSamples = reformatChemicalNames(getExportRes.data.coc_export_samples)
      const sampleIds = getExportRes.data.coc_export_samples.map(sample => sample.sample_id)

      getSamples(project.id, sampleIds)
        .then(getSamplesRes => {
          setLoading(false)
          const getSamplesSamples = getSamplesRes.data
          const combinedSamples = combineSampleArrays(getSamplesSamples, getExportSamples)
          const allSamplesWithSelected = addMissingSamples(combinedSamples, allSamples)
          setSelectedSamples(allSamplesWithSelected)
          setSelectedChemicals(getExportRes.data.chemical_names)
          updateHeaderDetails(getExportRes?.data.coc_billing_infos[0].description)
        })
    })
}

export const formatBoreholeSamples = (selectedSamples) => {
  let deconstructedArray = []

  selectedSamples.forEach(outerObj => {
    Object.keys(outerObj).forEach(key => {
      const innerObj = outerObj[key]
      Object.keys(innerObj).forEach(innerKey => {
        if (innerKey !== 'count') {
          deconstructedArray.push({ [innerKey]: innerObj[innerKey], selected: true })
        }
      })
    })
  })

  return deconstructedArray
}

const combineSampleArrays = (sampleArray1, sampleArray2) => {
  // sampleArray1 is the array of samples from the getSamples API call
  // sampleArray2 is the array of samples from the getExport API call
  const sampleMap2 = new Map(sampleArray2.map(obj => [obj.sample_id, obj]))

  return sampleArray1.reduce((combined, obj) => {
    const sampleId = Object.keys(obj)[0]
    const matchingObj = sampleMap2.get(sampleId)
    if (matchingObj) {
      combined.push({ ...obj, ...matchingObj, selected: true })
    }
    return combined
  }, [])
}

const reformatChemicalNames = (sampleArray) => {
  return [...sampleArray].map(sample => ({ ...sample, chemicalNames: sample?.chemical_names }))
}

const compareArraysByKey = (selectedArray, allArray) => {
  return selectedArray.some(obj1 => {
    const firstKey1 = Object.keys(obj1)[0];
    return allArray.some(obj2 => {
      const firstKey2 = Object.keys(obj2)[0];
      return firstKey1 === firstKey2;
    });
  });
}

const addMissingSamples = (selectedSamples, allSamples) => {
  // Create a map of keys from selectedSamples
  const keysMap = new Map(selectedSamples.map(obj => [Object.keys(obj)[0], true]))

  // Iterate over allSamples and add objects to selectedSamples if their key is not in keysMap
  allSamples.forEach(obj2 => {
    const key2 = Object.keys(obj2)[0]
    if (!keysMap.has(key2)) {
      const obj2Copy = { ...obj2 }
      obj2Copy.selected = false
      selectedSamples.push(obj2Copy)
    }
  });

  return selectedSamples
}

export const getTotalSelectedCount = (selectedSamples) => {
  return selectedSamples.filter(sample => sample.selected === true).length
}

const isDateInRange = (date, startDate, endDate) => {
  const normalizeUTC = (d) => Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());

  const sampleDate = normalizeUTC(new Date(date))
  const start = normalizeUTC(new Date(startDate))
  const end = normalizeUTC(new Date(endDate))

  return sampleDate >= start && sampleDate <= end
}

export const filterSamples = (selectedSamples, filters) => {
  const selectedSamplesCopy = [...selectedSamples]
  const filterKeys = Object.keys(filters)

  for (let sample of selectedSamplesCopy) {
    const sampleKey = Object.keys(sample)[0]
    let isSelected = true

    for (let key of filterKeys) {
      if (key === 'date_range') {
        if (filters[key].start_date && filters[key].end_date) {
          isSelected = isDateInRange(sample[sampleKey]['screening_date'], filters[key].start_date, filters[key].end_date)
        }
      } else if (filters[key].length !== 0) {
        isSelected = filters[key].includes(sample[sampleKey][key])
      }
      if (!isSelected) break
    }
    sample.selected = isSelected
  }
  return selectedSamplesCopy
}

export const getChemicalsCount = (selectedSamples) => {
  const chemicalsCount = {}
  selectedSamples.forEach(sample => {
    if (sample.selected && sample.chemicalNames) {
      sample.chemicalNames.forEach(chemical => {
        chemicalsCount[chemical] = chemicalsCount[chemical] ? chemicalsCount[chemical] + 1 : 1
      })
    }
  })
  return chemicalsCount
}

export const getApecsCount = (selectedSamples) => {
  const apecsCount = {}
  selectedSamples.forEach(sample => {
    if (sample.selected) {
      const sampleKey = Object.keys(sample)[0]
      const nestedSample = sample[sampleKey]

      apecsCount[nestedSample.apec] = apecsCount[nestedSample.apec] ? apecsCount[nestedSample.apec] + 1 : 1
    }
  })
  return apecsCount
}

export const removeChemicalFromSelectedSamples = (chemical, selectedChemicals, selectedSamples) => {
  if (selectedChemicals.includes(chemical)) {
    const selectedSamplesCopy = [...selectedSamples]

    selectedSamplesCopy.forEach(sample => {
      if (sample.chemicalNames.includes(chemical)) {
        sample.chemicalNames = sample.chemicalNames.filter(selectedChemicals => selectedChemicals !== chemical);
      }
    })
    return selectedSamplesCopy
  }
  else
    return selectedSamples
}

export const sortLabInformation = (labInformation) => {
  const uniqueCompanies = new Set()
  const uniqueCities = new Set()
  const uniqueAddresses = new Set()
  const uniquePhones = new Set()
  const uniqueEmails = new Set()
  const uniqueFaxes = new Set()

  labInformation.forEach(item => {
    uniqueCompanies.add(item.company)
    uniqueCities.add(item.city)
    uniqueAddresses.add(item.address)
    uniquePhones.add(item.phone)
    uniqueEmails.add(item.email)
    uniqueFaxes.add(item.fax)
  })

  return {
    companies: [...Array.from(uniqueCompanies).map(item => ({ value: item, label: item })), { value: 'Other', label: 'Other' }],
    cities: Array.from(uniqueCities).map(item => ({ value: item, label: item })),
    addresses: Array.from(uniqueAddresses).map(item => ({ value: item, label: item })),
    phones: Array.from(uniquePhones).map(item => ({ value: item, label: item })),
    emails: Array.from(uniqueEmails).map(item => ({ value: item, label: item })),
    faxes: Array.from(uniqueFaxes).map(item => ({ value: item, label: item }))
  }
}

export const filterLabs = (labInformation, filters) => {

  if (isObjectWithEmptyValues(filters)) return labInformation
  const filterKeys = Object.keys(filters)
  return labInformation.filter(lab => {
    let isSelected = true

    for (let key of filterKeys) {
      if (filters[key].length !== 0) {
        isSelected = filters[key].includes(lab[key])
      }
      if (!isSelected) break
    }
    return isSelected
  })
}

function isObjectWithEmptyValues(obj) {
  return _.every(_.values(obj), value =>
    _.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value)
  );
}

export const listCountDataToString = (selectedCount, type) => {
  if (isEmpty(selectedCount)) {
    return `No ${type} selected`
  } else {
    const entries = Object.entries(selectedCount).map(([selected, count]) => `${selected}- ${count}`)
    return entries.join(', ')
  }
}

export const emailBody = (cocExport) => {
  const stats = cocExport.coc_stats[0]?.stats

  const total = `TOTAL SAMPLES COLLECTED: \n ${cocExport.coc_export_samples.length}`
  const coolers = `COOLER COUNT: \n ${stats?.coolers ? stats?.coolers : 'None Added'}`
  const chemicals = stats?.chemicals ? listCountDataToString(stats?.chemicals, 'chemicals') : 'No Chemicals Selected'

  return `SELECTION STATS \n\n${total} \n\n${coolers} \n\nANALYSIS COUNT: \n${chemicals}`
}

export const emailSubject = (cocExport, projectName) => {
  const urgency = cocExport.coc_billing_infos[0]?.description.rush_priority
  const urgent = (urgency?.next_day || urgency?.same_day || urgency?.three_day) ? true : false
  const date = new Date().toUTCString()
  return `${urgent ? 'URGENT' : ''} ${projectName}  ${date}`
}

export const addNewLab = (labs, labInformation) => {
  const labExists = labs.some(lab => lab.address === labInformation.address)
  if (!labExists) {
    labs.push(labInformation)
  }
  return labs
}

export const getLoadedLabOptions = (labInformation) => {
  return Object.keys(labInformation).reduce((acc, key) => {
    acc[key] = { value: labInformation[key], label: labInformation[key] };
    return acc;
  }, {})
}
