import React from "react"
import Select from "react-select"
import { isEmpty } from "lodash"
import { useTrackerFilterAPI, useTrackerFilterContext } from "../../contexts/trackerFilterContext"

const TrackerFilters = () => {
  const { headerOptions, currentHeaderFilter, valueOptions } = useTrackerFilterContext()
  const { updateHeaderFilter, updateValueFilter } = useTrackerFilterAPI()

  const onFilterSelect = (e) => { updateHeaderFilter(e?.value) }
  const onColumnSelect = (e) => { updateValueFilter(e?.value) }

  return (
    <div className="m-4 z-10 flex flex-row gap-x-4">
      <Select
        options={headerOptions}
        onChange={onFilterSelect}
        className='z-20'
        isClearable='true'
        placeholder={'Choose Header to Filter'}
      />
      <Select
        options={valueOptions}
        onChange={onColumnSelect}
        className='w-1/2 z-20'
        isClearable
        isDisabled={isEmpty(currentHeaderFilter)}
        placeholder={'Choose Value to Filter for'}
      />
    </div>
  )
}

export default TrackerFilters
