import { faArrowCircleDown, faArrowCircleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo, useState } from 'react'
import { useMobileRecordContext } from "../../../contexts/mobileRecordContext"
import { recordEntryTableHeaders } from "../../../utilities/mobileRecordHelper"
import ChildRecord from './childRecord'

const GroupedChildRecords = ({ recordType, records, rootRecord }) => {
  const { formTemplates } = useMobileRecordContext()
  const [showRecords, setShowRecords] = useState(false)

  const formTemplate = useMemo(() => formTemplates[recordType], [recordType, formTemplates])
  const tableHeaders = useMemo(() => recordEntryTableHeaders(formTemplate), [formTemplate])
  const recordTypeLabel = useMemo(() => recordType?.replace('_', ' '), [recordType])

  return (
    <div className='flex-col bg-gray-200 flex w-full'>
      <div className='items-center flex hover:bg-gray-200 p-2 cursor-pointer gap-x-2 w-full'
        onClick={() => setShowRecords(!showRecords)}>
        <FontAwesomeIcon icon={showRecords ? faArrowCircleUp : faArrowCircleDown} size='lg' />
        <h2 className='w-8 uppercase whitespace-nowrap leading-none'>
          {recordTypeLabel}
        </h2>
      </div>
      {showRecords &&
        <div className="overflow-x-auto w-full p-2">
          <table className="table-auto border-collapse border border-gray-300" key={recordType}>
            <thead>
              <tr>
                {tableHeaders.map(header => <th key={header} className='border border-gray-300 p-2'>{header}</th>)}
              </tr>
              {records.map(record => <ChildRecord rootRecord={rootRecord} childRecord={record} key={record.id} />)}
            </thead>
          </table>
        </div>
      }
    </div>
  )
}

export default GroupedChildRecords 
