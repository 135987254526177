import React, { useEffect, useReducer } from "react";
import "react-datepicker/dist/react-datepicker.css";
import HeaderFields from "./HeaderFields";
import Export from "./export/index";
import Widget from "../shared/widget";

const initialBoreholeDetails = {
  selected_boreholes: [],
  project: 'Stage 2 Preliminary Site Investigation',
  client: '',
  legal: '',
  checked_by: '',
  useLatLon: false,
  utmZone: '',
}

const reducer = (boreholeDetails, action) => {
  if (action.type === 'changeValue') {
    return { ...boreholeDetails, [action.field]: action.value };
  }
  if (action.type === 'updateSelectedBoreholes') {
    return { ...boreholeDetails, selected_boreholes: action.value };
  }
  if (action.type === 'toggleUseLatLon') {
    return { ...boreholeDetails, useLatLon: !boreholeDetails.useLatLon }
  }
}

const BoreholeLog = ({ project, boreholes, dates, current_username, client, site }) => {

  const [boreholeDetails, dispatch] = useReducer(reducer, initialBoreholeDetails);

  const onInputChange = (field, value) => dispatch({ type: 'changeValue', field: field, value: value })

  const onBoreholeSelect = (value) => dispatch({ type: 'updateSelectedBoreholes', value })

  const onUseLatLonToggle = () => dispatch({ type: 'toggleUseLatLon' })

  useEffect(() => {
    if (project) {
      onInputChange('checked_by', current_username)
      site && onInputChange('legal', site)
      client && onInputChange('client', client)
    }
  }, [project, client, site])

  return (<>
    <div className='flex flex-row'>
      <div className='mx-10 my-10 flex flex-col gap-y-4 w-1/2'>
        <h1>{project.name} Borehole Log Export</h1>
        <HeaderFields
          boreholeDetails={boreholeDetails}
          onInputChange={onInputChange}
          onUseLatLonToggle={onUseLatLonToggle}
          determineUtmZone={project.mobile_data_provider === 'terrago_gen_4'}
        />
        <Export
          boreholes={boreholes}
          project={project}
          boreholeDetails={boreholeDetails}
          dates={dates}
          onBoreholeSelect={onBoreholeSelect} />
      </div>
      <Widget widgetProp={project?.default_widget?.full_url} project={project} />
    </div>
  </>)
}

export default BoreholeLog
