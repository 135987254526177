import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getBoreholeId } from "../../utilities/mobileRecordHelper"


const MobileRecordRow = ({ mobileRecord }) => {
  const [isSelected, setIsSelected] = useState(false)
  const { noteId, projectId } = useParams()

  const recordType = useMemo(() => mobileRecord.record_type?.replace(/_/g, " "), [mobileRecord])
  const boreholeName = useMemo(() => {
    return (mobileRecord.record_type === 'Borehole_Details') ? getBoreholeId(mobileRecord) : recordType
  }, [mobileRecord, recordType])

  const mobileRecordRoute = `/projects/${projectId}/notes/${mobileRecord.id}`

  useEffect(() => {
    setIsSelected(noteId && mobileRecord.id === parseInt(noteId))
  }, [noteId])

  return (
    <div className='divide-x divide-gray-200 min-w-32'>
      <div
        className={`bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase tracking-wider flex flex-row justify-between items-center ${isSelected ? 'bg-gray-100' : 'bg-white'}`}>
        <Link to={mobileRecordRoute} className='flex flex-row gap-x-2 items-center'>
          <FontAwesomeIcon className='cursor-pointer w-8' icon={isSelected ? faChevronDown : faChevronRight} />
          <div className="flex flex-col">
            <p>{recordType}</p>
            <p>{boreholeName}</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default MobileRecordRow
