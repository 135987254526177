import { faCheckSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useMemo } from 'react'
import { useCocExport, useCocExportApi } from "../../contexts/cocExport"
import { deselectAll, selectAll } from "../../utilities/cocExportHelpers"
import CocFilters from './CocFilters'
import CocHeaders from "./CocHeaders"
import ExportBoreholeSelect from './ExportBoreholeSelect'

const ExportOptions = ({ boreholes, filterValues }) => {
  const { selectedChemicals, includeChangeOfControl, selectedSamples, selectedSamplesCount } = useCocExport()
  const { updateSelectedSamples, updateSelectedChemicals } = useCocExportApi()

  const isSelectAll = useMemo(() => selectedSamples.length === selectedSamplesCount, [selectedSamples, selectedSamplesCount])
  const toggleSelectAll = () => {
    if (!isSelectAll) {
      updateSelectedSamples(selectAll(selectedSamples))
    }
    else {
      updateSelectedSamples(deselectAll(selectedSamples))
    }
  }

  const removeChemical = (chemicalname) => {
    const newChemicals = selectedChemicals.filter(item => item !== chemicalname)
    updateSelectedChemicals(newChemicals, chemicalname)
  }

  const chemicalsList = useMemo(() => {
    return selectedChemicals.map(chemical =>
      <th key={chemical} className='font-semibold border-regular-orange border px-2 sticky top-0 bg-white z-10'>
        {chemical}
        &nbsp;&nbsp;
        <FontAwesomeIcon icon={faTrash} size='lg' onClick={() => removeChemical(chemical)} className="hover:cursor-pointer" />
      </th>
    )
  }, [selectedChemicals])

  const boreholeList = useMemo(() => boreholes.map(borehole => <ExportBoreholeSelect key={Object.keys(borehole)[0]} borehole={borehole} />), [boreholes])

  return (
    <div className='my-4 py-2 border-b border-regular-blue'>
      <h1 className='my-2'>Export Options</h1>
      <h2>Start and End Depth: {filterValues?.depth_range?.start_depth} - {filterValues?.depth_range?.end_depth}m </h2>
      <div className='flex flex-row gap-x-2'>
        <table className='block overflow-scroll h-table'>
          <thead>
            <tr>
              <th className='sticky top-0 bg-white z-10 border border-black'>
                Borehole Name
              </th>
              <th className='sticky top-0 bg-white z-10 border border-black'>
                <p className="text-xs">Select All</p>
                <div
                  className='cursor-pointer flex ml-4'
                  onClick={() => toggleSelectAll(isSelectAll)}
                >
                  {isSelectAll ?
                    <FontAwesomeIcon icon={faCheckSquare} size='lg' className='text-green-500' /> :
                    <FontAwesomeIcon icon={faTimes} size='lg' />}
                </div>
              </th>
              <th className='px-2 sticky top-0 bg-white border border-black'>Sample Name</th>
              <th className='px-2 sticky top-0 bg-white border border-black'>APEC Type</th>
              <th className='px-2 sticky top-0 bg-white border border-black'>Screening Date</th>
              <th className='px-2 sticky top-0 bg-white border border-black'>Avg. Depth(m)</th>
              <CocHeaders includeChangeOfControl={includeChangeOfControl} />
              {chemicalsList}
            </tr>
          </thead>
          <tbody>
            {boreholeList}
          </tbody>
        </table>
        <CocFilters filterValues={filterValues} />
      </div>
    </div>
  )
}

export default ExportOptions
