import React, { useEffect, useMemo, useRef } from "react";
import { useTimesheetContext } from "../../../contexts/timesheetContext";
import { useTimeTableApi, useTimeTableContext } from "../../../contexts/timeTableContext";
import { useTimesheetEntry } from "../../../hooks/useTimesheetEntry";
import { getDateFromWeekStartAndDay } from "../../../utilities/dateHelpers";
import ModalWrapper from "../../modal/modalWrapper";
import RenderModal from "../../modal/renderModal";
import ConsumablesForm from "./forms/consumablesForm";
import TimeEntryForm from "./forms/timeEntryForm";

const TimesheetEntryModal = () => {
  const timeEntryRef = useRef();
  const consumableRef = useRef();
  const { toggleState, updateEditEntryState, updateEntries, createEntries, deleteEntries } = useTimeTableApi()
  const { userId, editModal, editEntry } = useTimeTableContext()
  const { projects, portfolios, tasks, weekStart, consumables } = useTimesheetContext()
  const { id, selectedDay, day_entry_id } = editEntry

  const { formattedWeekStart, weekEnd, updateField }
    = useTimesheetEntry({ userId, editEntry, projects, portfolios, tasks, weekStart, updateEditEntryState, consumables })

  useEffect(() => {
    if (selectedDay) {
      updateField("date", getDateFromWeekStartAndDay(weekStart, selectedDay))
    }
  }, [selectedDay, weekStart, updateField])

  const onSubmitHandler = useMemo(() => id ? updateEntries : createEntries, [id])

  return (
    <>
      {editModal && (
        <RenderModal>
          <ModalWrapper
            heading={`${formattedWeekStart} - ${weekEnd}`}
            closeModal={() => toggleState("editModal")}
            onSend={() => onSubmitHandler({ timeEntryRef, consumableRef })}
            submitButtonText="Submit"
          >
            <TimeEntryForm timeEntryFormRef={timeEntryRef} />

            <ConsumablesForm consumableRef={consumableRef} />
            
            {id && (
              <button onClick={() => deleteEntries(day_entry_id, id)} className="bg-red-600 font-semibold px-4 py-2 text-white rounded mt-5">
                Delete Entry
              </button>
            )}
          </ModalWrapper>
        </RenderModal>
      )}
    </>
  );
};

export default TimesheetEntryModal;
