import { isEmpty } from 'lodash'
import React from 'react'
import { useMobileRecordContext } from '../../contexts/mobileRecordContext'
import BoreholeDetails from './mobileRecordDisplays/boreholeDetails'
import ChildRecords from './mobileRecordDisplays/childRecords'

const MobileRecord = () => {
  const { mobileRecord } = useMobileRecordContext()

  return (
    !isEmpty(mobileRecord) ?
      <div className='flex flex-row flex-1 bg-gray-100 h-full'>
        {mobileRecord.form_record_type === 'Borehole_Details' && (
          <BoreholeDetails>
            <ChildRecords rootRecord={mobileRecord} />
          </BoreholeDetails>
        )}
        {mobileRecord.form_record_type !== 'Borehole_Details' && <ChildRecords rootRecord={mobileRecord} />}
      </div>
      : <></>
  )
}
export default MobileRecord
