import React from "react";
import BoreholeHeaderField from "./BoreholeHeaderField";
import CoordinatesField from "./CoordinatesField";

const HeaderFields = ({ boreholeDetails, onInputChange, onUseLatLonToggle, determineUtmZone }) => {
  return (<>
    <h2>Header Information</h2>
    <div className='flex flex-col gap-y-3'>
      <BoreholeHeaderField title={'Project'} field={'project'} boreholeDetails={boreholeDetails}
                           onInputChange={onInputChange} />
      <BoreholeHeaderField title={'Client'} field={'client'} boreholeDetails={boreholeDetails}
                           onInputChange={onInputChange} />
      <BoreholeHeaderField title={'Legal'} field={'legal'} boreholeDetails={boreholeDetails}
                           onInputChange={onInputChange} />
      <BoreholeHeaderField title={'Checked By'} field={'checked_by'} boreholeDetails={boreholeDetails}
                           onInputChange={onInputChange} />
      <CoordinatesField boreholeDetails={boreholeDetails} onUseLatLonToggle={onUseLatLonToggle}
                        onInputChange={onInputChange} determineUtmZone={determineUtmZone} />
    </div>
  </>)
}

export default HeaderFields
