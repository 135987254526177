import { format, isValid } from "date-fns"

export const parseDateString = (dateString) => {
  try {
    const date = new Date(dateString)
    return isValid(date) ? date : null
  } catch (e) {
    return null
  }
}

export const compareDate = (a, b) => {
  if (parseDateString(a.date) > parseDateString(b.date) || !b.date) {
    return -1;
  }

  if (parseDateString(a.date) < parseDateString(b.date) || !a.date) {
    return 1;
  }
  return 0;
}

export const syncAtFormatter = (dateObj = new Date()) => format(dateObj, 'yyyy-MM-dd @ hh:mmaaa zzzz')

export const formatDateTimeStr = (str, opts = 'yyyy-MM-dd @ hh:mmaaa zzzz') => {
  const date = new Date(str)
  return format(date, opts)
}

export const stringToUTCDate = (str) => {
  if (!str) {
    return str
  }
  let date = new Date(str)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}

export const formatDateYearMonthDay = (date) => {
  if (!date) {
    return date
  }
  return format(date, 'yyyy-MM-dd')
}

export const formatDateString = (str) => {
  const date = new Date(str)
  const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric', timeZone: 'UTC' }
  return date.toLocaleDateString('en-US', options)
}

export const getWeekStart = dateStr => {
  const date = stringToUTCDate(new Date(dateStr))
  const diff = date.getDate() - date.getDay()
  return new Date(date.setDate(diff))
}

export const getWeekEnd = dateStr => {
  const date = stringToUTCDate(new Date(dateStr))
  const diff = date.getDate() + 6 - date.getDay()
  return new Date(date.setDate(diff))
}

export const incrementDay = (dateStr, increment) => {
  const date = stringToUTCDate(new Date(dateStr))
  const diff = date.getDate() + increment
  return new Date(date.setDate(diff))
}

export const dateWithinRange = (date, start) => {
  const end = incrementDay(start, 6)
  return date >= start && date <= end
}

export const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const setToDateRange = (dateSet) => {
  const dateArray = Array.from(dateSet).map(dateStr => new Date(dateStr))

  const startDate = new Date(Math.min(...dateArray))
  const endDate = new Date(Math.max(...dateArray))

  const dateRange = {
    start_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate() + 1}`,
    end_date: `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate() + 1}`
  }
  return dateRange

}


export const getDateFromWeekStartAndDay = (weekStart, selectedDay) => {
  if (!selectedDay) return null

  const dayIndices = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }

  const weekStartDate = new Date(weekStart)
  const dayIndex = dayIndices[selectedDay.toLowerCase()]

  if (dayIndex === undefined) return null

  const date = new Date(weekStartDate)
  date.setDate(date.getDate() + dayIndex)

  return date
}
