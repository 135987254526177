import React, { useReducer } from 'react'
import { useCocExportApi } from '../../contexts/cocExport'
import * as filter from '../filters'
import TableTotals from './TableTotals'

const initialFilterOptions = {
  borehole_id: [],
  apec: [],
  date_range: { start_date: null, end_date: null }
}

const reducer = (filterOptions, action) => {
  if (action.type === 'updateSelectedBoreholes') {
    return { ...filterOptions, borehole_id: action.value }
  }
  if (action.type === 'updateSelectedApecs') {
    return { ...filterOptions, apec: action.value }
  }
  if (action.type === 'updateSelectedStartDate') {
    return { ...filterOptions, date_range: { ...filterOptions.date_range, start_date: action.value } }
  }
  if (action.type === 'updateSelectedEndDate') {
    return { ...filterOptions, date_range: { ...filterOptions.date_range, end_date: action.value } }
  }
}

const CocFilters = ({ filterValues }) => {
  const { filterSamples } = useCocExportApi()
  const [filterOptions, dispatch] = useReducer(reducer, initialFilterOptions)
  const onBoreholeSelect = (value) => { return dispatch({ type: 'updateSelectedBoreholes', value }) }
  const onApecSelect = (value) => { return dispatch({ type: 'updateSelectedApecs', value }) }
  const onEndDateSelect = (value) => { return dispatch({ type: 'updateSelectedEndDate', value }) }
  const onStartDateSelect = (value) => { return dispatch({ type: 'updateSelectedStartDate', value }) }

  const onClick = () => {
    filterSamples(filterOptions)
  }

  return (
    <div className='border border-blue-500 p-4 rounded-lg m-4 flex'>
      <div className='w-1/2'>
        <h1 className='text-1/4'><strong>FILTERS</strong></h1>
        <filter.DateFilter dateRange={filterValues.date_range} onEndDateSelect={onEndDateSelect} onStartDateSelect={onStartDateSelect} />
        <filter.OptionFilter options={filterValues.borehole_id.length === 0 ? ["N/A"] : filterValues.borehole_id} onSelect={onBoreholeSelect} name={"boreholes"} />
        <filter.OptionFilter options={filterValues.apec.length === 0 ? ["N/A"] : filterValues.apec} onSelect={onApecSelect} name={"apecs"} />
        <button
          className={`border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2 py-1 transition duration-500 select-none text-xl font-bold w-64 text-center m-2`}
          onClick={onClick}
        >
          Filter
        </button>
      </div>
      <div className='w-1/2'>
        <TableTotals />
      </div>
    </div>
  )
}

export default CocFilters
