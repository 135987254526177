import React, { useMemo } from 'react'
import { usePhotoAPIContext, usePhotoContext } from '../../../contexts/photoContext'
import Comment from './comment'

const Photo = ({ image }) => {
  const { updateOpenIndex, updateSelectedPhoto } = usePhotoAPIContext()
  const { comments, selectedPhotos, exportStatus } = usePhotoContext()
  const imageGuid = useMemo(() => image.slide.title, [image])
  const comment = useMemo(() => comments[image.details?.comment_id], [image, comments[image.details.comment_id]])

  const selected = useMemo(() => {
    return selectedPhotos.findIndex(p => p.photo_guid === imageGuid) + 1
  }, [selectedPhotos, imageGuid])

  const onClick = (imageGuid) => {
    if (!exportStatus) {
      updateOpenIndex(imageGuid)
    }
    else if (exportStatus) {
      updateSelectedPhoto(image, comment?.description)
    }
  }

  return (
    <div className={`${exportStatus && !!selected ? 'bg-blue-300' : ''} grid grid-cols-1 m-auto h-full`}>
      {exportStatus && !!selected &&
        <span className='btn btn-green font-bold w-10 text-center mx-4'>
          {selected}
        </span>
      }
      <div className='grid gap-x-1 m-4' >
        <div className='flex flex-col justify-between h-full' >
          <img alt={imageGuid}
            src={image.slide.src}
            className='cursor-pointer'
            onClick={() => onClick(imageGuid)}
          />
        </div>
        <div className='bg-gray-200 grid grid-cols-1 mt-auto'>
          <Comment comment={comment} id={[image.details.comment_id]} />
        </div>
      </div>
    </div>
  )
}

export default Photo
