import React, { useMemo } from 'react'
import { useTimesheetContext } from '../../../contexts/timesheetContext'
import { useTimeTableApi, useTimeTableContext } from '../../../contexts/timeTableContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import { projectManagerOrGreater } from '../../../utilities/userHelpers'
import { notifyAlert } from "../../shared/notice"
import DayEntry from './dayEntry'

const TimeTableEntryRow = ({ entry, entryDay }) => {
  const { tasks, projects, user } = useTimesheetContext()
  const { initEditModal } = useTimeTableApi()
  const { week } = useTimeTableContext()
  const task = useMemo(() => tasks.find(task => task.id === entry.timesheet_task_id), [tasks, entry])
  const project = useMemo(() => projects[entry.project_id], [projects, entry])
  const lockingConditions = week[entryDay].approved && !projectManagerOrGreater(user)
  const tdStyle = lockingConditions? tableStyles.tdApproved : tableStyles.td

  const onClick = () => {
    lockingConditions ? notifyAlert(`${entryDay.charAt(0).toUpperCase() + entryDay.slice(1)} has been approved and cannot be edited! Talk to your Project Manager if you need to make changes.`) :
      initEditModal({ ...entry, portfolio_id: project.portfolio_id, user_id: week.user_id, selectedDay: entryDay, day_entry_id: week[entryDay].id, consumable_entries: entry.consumable_entries })
  }
  return (
    <tr onClick={onClick}>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.job_number}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.historical_project_number}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project?.owner_organization_name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project?.service_organization_name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{project.name}</p>
      </td>
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{task.name}</p>
      </td>
      <DayEntry tdStyle={tdStyle} entryDay={entryDay} entry={entry} />
      <td className={tdStyle}>
        <p className={tableStyles.tdText}>{entry.description}</p>
      </td>
    </tr>
  )
}

export default TimeTableEntryRow
