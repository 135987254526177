import API, { simpleGet, simplePatch, simplePost } from "./apiConfig"
import { updateConsumableEntryForm } from "../utilities/timesheetHelpers"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const getTimesheet = () => simpleGet('/timesheets.json')

export const createNewTimeEntry = (dayEntryId, formData) => simplePost(`/timesheet/day_entries/${dayEntryId}/time_entries`, formData)

export const createDayEntry = (formData) => simplePost('/timesheet/day_entries', formData)

export const updateTimeEntryAPI = (dayEntryId, entryId, formData) => simplePatch(`/timesheet/day_entries/${dayEntryId}/time_entries/${entryId}`, formData)

export const getTimesheetEntries = (userIds) => simpleGet('/timesheet_entries.json', { params: { users_ids: userIds } })

export const getWeekOfDayEntries = (date, userIds) => simpleGet('/timesheet/day_entries/get_week.json', {
  params: {
    date: date,
    user_ids: userIds
  }
})

export const updateDayEntryAPI = (dayEntryId, params) => simplePatch(`/timesheet/day_entries/${dayEntryId}`, params)

export const deleteTimesheetTimeEntry = (dayEntryId, timeEntryId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`/timesheet/day_entries/${dayEntryId}/time_entries/${timeEntryId}`)
}

export const createUpdateAllConsumableEntries = (dayEntryId, consumableFormData, timeEntryId) => {
  const updatedConsumableFormData = updateConsumableEntryForm(consumableFormData, "[timesheet_time_entry_id]", timeEntryId)
  return simplePost(`/timesheet/day_entries/${dayEntryId}/consumable_entries/create_update_all`, updatedConsumableFormData)
}
