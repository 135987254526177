import { isEmpty } from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { getProjectNotes } from "../../../api/projectApi"
import { useNotesContext } from "../../../contexts/notesContext"
import Breadcrumb from '../../../layouts/nav/breadcrumb'
import Breadcrumbs from "../../../layouts/nav/breadcrumbs"

function BreadCrumbs({ portfolio, project, children }) {
  const { notes } = useNotesContext()
  const [notesCount, setNotesCount] = useState(0)
  const { projectId } = useParams()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('notes') && project?.uuid) {
      getProjectNotes(project.id)
        .then(res => setNotesCount(res.data?.notes_count || null))
    }
  }, [project])

  const notesText = useMemo(() => {
    return [
      'Notes',
      (notesCount && notes) && `(${notes?.length || 0}/${notesCount} Notes)`
    ].filter(i => i).join(" ")
  }, [notesCount, notes])

  return (
    <Breadcrumbs>
      {!isEmpty(portfolio) && (
        <Breadcrumb linkPath={`/portfolios/${portfolio.id}`} linkName={portfolio?.name} firstLink={true} />
      )}
      <Breadcrumb linkPath={`/projects/${project.id}`} linkName={project?.name} firstLink={isEmpty(portfolio)} />
      {location.pathname.includes('notes') && (
        <Breadcrumb
          linkPath={`/projects/${projectId}/notes`}
          linkName={notesText}
          linkStyle=''
          routerLink={true}
        />
      )}
      {children}
    </Breadcrumbs>
  )
}

export default BreadCrumbs
