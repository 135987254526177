import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useMobileRecordContext } from '../../../contexts/mobileRecordContext'
import EntryValue from "./entryValue"

const ChildRecord = ({ rootRecord, childRecord }) => {
  const { descendantRecords, formTemplates } = useMobileRecordContext()
  const mobileRecord = useMemo(() => {
    return descendantRecords[rootRecord?.uuid]?.[childRecord?.uuid]
  }, [descendantRecords, rootRecord, childRecord])
  const formTemplate = useMemo(() => formTemplates[mobileRecord?.form_record_type], [mobileRecord, formTemplates])

  return (<tr>
    {!isEmpty(mobileRecord) &&
      <>
        {formTemplate?.fields?.map(field => {
          const entry = mobileRecord?.entries.find(entry => entry.position === field.position) || {}
          return (
            <td key={entry.id}>
              <EntryValue key={`${field.id}-${entry.id}`} entry={entry}
                map={[rootRecord.uuid, mobileRecord?.uuid]} />
            </td>
          )
        })}
      </>}
  </tr>
  )
}

export default ChildRecord
