import { cloneDeep } from "lodash"
import { daysOfWeek } from "../constants"

export const optionize = (value, label = value) => ({ value, label })

export const reducePortfolios = portfolios => {
  return Object.keys(portfolios).map(portfolio_id => optionize(portfolio_id, portfolios[portfolio_id]))
}

export const reduceProjects = (projects, portfolioId) => {
  return Object.keys(projects).reduce((acc, projectId) => {
    if (portfolioId && parseInt(portfolioId) !== projects[projectId]?.portfolio_id) return acc
    acc[0].push(optionize(projectId, projects[projectId].job_number))
    acc[1].push(optionize(projectId, projects[projectId].name))
    if (projects[projectId].historical_project_number) {
      acc[2].push(optionize(projectId, projects[projectId].historical_project_number))
    }
    return acc
  }, [[], [], []])
}

export const projectOptionsValues = (projects, projectId) => {
  if (projectId) {
    return [
      optionize(projectId, projects[projectId].job_number),
      optionize(projectId, projects[projectId].name),
      optionize(projectId, projects[projectId].historical_project_number),
    ]
  } else {
    return [null, null, null]
  }
}

export const timeEntryApiVerifier = (formRef) => {
  const formData = new FormData(formRef.current)
  const requiredFields = ['project_id', 'timesheet_task_id', 'date', 'hours']

  return requiredFields.every((field) => formData.get(field)) ? formData : null
}

export const consumableEntriesApiVerifier = (formRef) => {
  const formData = new FormData(formRef.current)
  const formDataEntries = [...formData.entries()]

  const parsedEntries = {}

  formDataEntries.forEach(([key, value]) => {
    const match = key.match(/consumable_entries_attributes\[(\d+)]\[(.+)]/);
    if (match) {
      const index = match[1]
      const field = match[2]

      if (!parsedEntries[index]) {
        parsedEntries[index] = {}
      }

      parsedEntries[index][field] = value
    }
  })

  const requiredCheck = !Object.values(parsedEntries).some(
    (entry) => entry.required === "true" && !entry.quantity
  )

  return requiredCheck ? formData : null
}


export const optionizeTasks = (tasks) => tasks.map(task => optionize(task.id, task.name))

const sumHours = timeEntries => timeEntries?.reduce((acc, entry) => acc + parseFloat(entry?.hours), 0.0) || 0

export const weeklyTotals = entries => {
  const sundayTotal = sumHours(entries?.sunday?.time_entries)
  const mondayTotal = sumHours(entries?.monday?.time_entries)
  const tuesdayTotal = sumHours(entries?.tuesday?.time_entries)
  const wednesdayTotal = sumHours(entries?.wednesday?.time_entries)
  const thursdayTotal = sumHours(entries?.thursday?.time_entries)
  const fridayTotal = sumHours(entries?.friday?.time_entries)
  const saturdayTotal = sumHours(entries?.saturday?.time_entries)

  return {
    sundayTotal: sundayTotal,
    mondayTotal: mondayTotal,
    tuesdayTotal: tuesdayTotal,
    wednesdayTotal: wednesdayTotal,
    thursdayTotal: thursdayTotal,
    fridayTotal: fridayTotal,
    saturdayTotal: saturdayTotal,
    total: sundayTotal + mondayTotal + tuesdayTotal + wednesdayTotal + thursdayTotal + fridayTotal + saturdayTotal
  }
}

export const addTimeTables = (timeTables, newTimeTables) => {
  return { ...timeTables, ...newTimeTables }
}

export const getUsername = (users, userId) => {
  const user = users.find(user => user[0] === parseInt(userId))
  return user ? user[1] : "Unknown User"
}


export const updateDayEntry = (week, dayEntry) => {
  const weekCopy = { ...week }
  const dayName = getWeekDayName(week, dayEntry.date)
  weekCopy[dayName] = dayEntry
  return weekCopy
}

function findDayEntry(timesheet, entryId) {
  for (const [dayName, dayEntry] of Object.entries(timesheet)) {
      if ( dayEntry.id === parseInt(entryId)) {
          return { dayName, dayEntry };
      }
  }
  return null;
}


export const updateTimeEntryValue = (week, dayEntryId, timeEntry) => {
  const weekCopy = { ...week }
  const { dayName, dayEntry } = findDayEntry(week, dayEntryId)
  const entryIndex = dayEntry?.time_entries.findIndex(entry => entry.id === timeEntry.id)

  if (entryIndex === -1) {
    dayEntry?.time_entries.push(timeEntry)
  } else {
    dayEntry.time_entries[entryIndex] = timeEntry
  }

  weekCopy[dayName] = dayEntry
  return weekCopy
}


const getWeekDayName = (week, dateString) => {
  const date = new Date(`${dateString}T00:00:00`)
  const dayName = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase()
  return dayName
}

export const updateTimeEntryWithId = (week, timeEntry) => {
  const weekCopy = { ...week }
  for (let i = 0; i < daysOfWeek.length; i++) {
    const day = daysOfWeek[i]
    const dayEntry = weekCopy[day]

    if (dayEntry.id === timeEntry.timesheet_day_entry_id) {
      dayEntry.time_entries = dayEntry.time_entries.map(entry => {
        return entry.id === timeEntry.id ? timeEntry : entry
      })
      weekCopy[day] = dayEntry
      break
    }
  }
  return weekCopy
}

export const removeTimeEntryWithId = (week, dayEntryId, timeEntryId) => {
  const weekCopy = { ...week }
  for (let i = 0; i < daysOfWeek.length; i++) {
    const day = daysOfWeek[i]
    const dayEntry = weekCopy[day]
    if (dayEntry.id === dayEntryId) {
      const updatedTimeEntries = dayEntry.time_entries.filter(entry => entry.id !== timeEntryId)
      const updatedDayEntry = { ...dayEntry, time_entries: updatedTimeEntries }
      weekCopy[day] = updatedDayEntry
      break
    }
  }
  return weekCopy
}

export const getSelectedConsumables = ({ selectedTask, consumables }) => {
  const selectedConsumables = []
  selectedTask?.task_consumables.forEach(taskConsumable => {
    const consumable = cloneDeep(consumables.find(consumable => consumable.id === taskConsumable.timesheet_consumable_id))
    consumable.required = taskConsumable.required
    consumable.task_consumable_id = taskConsumable.id
    selectedConsumables.push(consumable)
  })
  return selectedConsumables
}

export const updateConsumableEntryForm = (form, field, newValue) => {

  const formDataEntries = [...form.entries()];
  formDataEntries.forEach(([key, value]) => {
    if (key.includes(field)) {
      form.set(key, newValue)
    }
  })
  return form
}
