import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { daysOfWeek } from '../../../constants';
function DayEntry({ tdStyle, entryDay, entry }) {
  const cellClass = useMemo(() => {
    if (isEmpty(entry.consumable_entries)) {
      return tdStyle
    }
    const updatedTdStyle = tdStyle.replace('bg-white', '').trim();
    return `${updatedTdStyle} bg-gray-300 `
  }, [tdStyle, entry])

  return (<>
    {daysOfWeek.map((day) => (
      <td className={cellClass} key={day}>
        {entryDay === day ? entry.hours : null}
      </td>
    ))}
  </>
  )
}

export default DayEntry
