import axios from "axios"
import { notifyError } from "../components/shared/notice"

export const getProject = (projectId) => {
  try {
    return axios.get(`/projects/${projectId}.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const getProjectNotes = (projectId) => {
  try {
    return axios.get(`/projects/${projectId}/remote_notes_count.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const updateProjectLandingPage = (projectId, hasLandingPage) => {
  try {
    return axios.put(`/projects/${projectId}`, { has_landing_page: !hasLandingPage })
  }
  catch (e) {
    notifyError('There was an error')
  }
}

export const updateLandingPageWidget = (projectId, widget) => {
  try {
    return axios
      .put(`/projects/${projectId}`, { widget: widget })
  }
  catch (e) {
    notifyError('There was an error')
  }
}

export const updateProjectStatus = (projectId, status) => {
  try {
    return axios
      .post(`/projects/${projectId}/update_status`, { status: status })
  }
  catch (e) {
    notifyError('There was an error')
  }
}

export const getFormTemplates = (projectId) => {
  try {
    return axios
      .get(`/projects/${projectId}/form_templates`)
  }
  catch (e) {
    notifyError('There was an error')
  }
}

export const getProjectRoutes = (projectId) => {
  try {
    return axios
      .get(`/projects/${projectId}/project_routes`)
  }
  catch (e) {
    notifyError('There was an error')
  }
}

