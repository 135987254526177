import * as Actions from '../actions/cocExport'
import {
  addNewLab,
  filterLabs,
  filterSamples,
  getApecsCount,
  getChemicalsCount,
  getLoadedLabOptions,
  getTotalSelectedCount,
  removeChemicalFromSelectedSamples,
  sortLabInformation
} from '../utilities/cocExportHelpers'

export const initialCocExportState = (allSamples = [], labs = []) => ({
  includeChangeOfControl: false,
  selectedChemicals: [],
  selectedSamples: allSamples,
  allSamples: JSON.parse(JSON.stringify(allSamples)),
  headerDetails: {
    project_information: {
      project_id: '',
      project_name: '',
      project_location: '',
      legal_location: '',
      po: '',
      account_code: '',
      quote: ''
    },
    invoice_to: {
      company: '',
      address: '',
      phone: '',
      fax: '',
      email: ''
    },
    report_to: {
      company: '',
      address: '',
      phone: '',
      fax: '',
      email: ''
    },
    additional_report_to: {
      first_name: '',
      first_email: '',
      second_name: '',
      second_email: ''
    },
    lab_information: {
      company: '',
      address: '',
      phone: '',
      fax: '',
      email: ''
    },
    sample_custody: {
      sampled_by: '',
      company: '',
      signature: '',
      date: '',
    },
    notes: '',
    rush_priority: {
      same_day: false,
      next_day: false,
      three_days: false,
      five_days: false
    },
    report_results: {
      email: false,
      online: false,
      fax: false,
      qa_qc: false,
      pdf: false,
      excel: false
    },
    requirements: {
      hcdworg: false,
      ab_tier_1: false,
      spigec: false,
      bccsr: false
    }
  },
  exportHistory: [],
  selectedSamplesCount: allSamples.length,
  selectedChemicalsCount: {},
  selectedApecsCount: {},
  labFilters: { company: '', address: '', phone: '', fax: '', email: '' },
  numberOfCoolers: 0,
  emailInfo: { subject: '', body: '' },
  labs: labs,
  labOptions: sortLabInformation(labs),
  loadedLabSelection: { company: '', address: '', phone: '', fax: '', email: '' }
})

export const initialCocExportApiState = {
  toggleChangeOfControl: () => {},
  updateSelectedChemicals: () => {},
  updateSelectedSamples: () => {},
  updateProjectInformation: () => {},
  updateInvoiceTo: () => {},
  updateReportTo: () => {},
  updateLabInformation: () => {},
  updateSampleCustody: () => {},
  updateNotes: () => {},
  updateRushPriority: () => {},
  updateReportResults: () => {},
  updateRequirements: () => {},
  setAllSamples: () => {},
  updateExportHistory: () => {},
  updatedLabFilters: () => {},
  setNumberOfCoolers: () => {},
  updateEmailInfo: () => {},
  setLabs: () => {},
  updateHeaderDetails: () => {}
}

export const cocExportReducer = (state, action) => {
  switch (action.type) {
    case Actions.TOGGLE_CHANGE_OF_CONTROL:
      return { ...state, includeChangeOfControl: !state.includeChangeOfControl }
    case Actions.UPDATE_SELECTED_CHEMICALS:
      const updatedSelectedSamples = removeChemicalFromSelectedSamples(action.update_chemical, state.selectedChemicals, state.selectedSamples)
      const updatedSelectedChemicalsCount = getChemicalsCount(updatedSelectedSamples)
      return { ...state, selectedChemicals: action.chemicals, selectedSamples: updatedSelectedSamples, selectedChemicalsCount: updatedSelectedChemicalsCount }
    case Actions.UPDATE_SELECTED_SAMPLES:
      return { ...state, selectedSamples: action.samples, selectedSamplesCount: getTotalSelectedCount(action.samples), selectedChemicalsCount: getChemicalsCount(action.samples), selectedApecsCount: getApecsCount(action.samples) }
    case Actions.UPDATE_PROJECT_INFORMATION:
      return { ...state, headerDetails: { ...state.headerDetails, project_information: { ...state.headerDetails.project_information, [action.field]: action.value } } }
    case Actions.UPDATE_INVOICE_TO:
      return { ...state, headerDetails: { ...state.headerDetails, invoice_to: { ...state.headerDetails.invoice_to, [action.field]: action.value } } }
    case Actions.UPDATE_REPORT_TO:
      return { ...state, headerDetails: { ...state.headerDetails, report_to: { ...state.headerDetails.report_to, [action.field]: action.value } } }
    case Actions.UPDATE_LAB_INFORMATION:
      return { ...state, headerDetails: { ...state.headerDetails, lab_information: { ...state.headerDetails.lab_information, [action.field]: action.value } } }
    case Actions.UPDATE_ADDITIONAL_REPORT_TO:
      return { ...state, headerDetails: { ...state.headerDetails, additional_report_to: { ...state.headerDetails.additional_report_to, [action.field]: action.value } } }
    case Actions.UPDATE_SAMPLE_CUSTODY:
      return { ...state, headerDetails: { ...state.headerDetails, sample_custody: { ...state.headerDetails.sample_custody, [action.field]: action.value } } }
    case Actions.UPDATE_NOTES:
      return { ...state, headerDetails: { ...state.headerDetails, notes: action.notes } }
    case Actions.UPDATE_RUSH_PRIORITY:
      return { ...state, headerDetails: { ...state.headerDetails, rush_priority: { ...state.headerDetails.rush_priority, [action.field]: action.value } } }
    case Actions.UPDATE_REPORT_RESULTS:
      return { ...state, headerDetails: { ...state.headerDetails, report_results: { ...state.headerDetails.report_results, [action.field]: action.value } } }
    case Actions.UPDATE_REQUIREMENTS:
      return { ...state, headerDetails: { ...state.headerDetails, requirements: { ...state.headerDetails.requirements, [action.field]: action.value } } }
    case Actions.SET_ALL_SAMPLES:
      return { ...state, allSamples: action.allSamples }
    case Actions.UPDATE_EXPORT_HISTORY:
      return { ...state, exportHistory: action.exportHistory }
    case Actions.FILTER_SAMPLES:
      const filteredSamples = filterSamples([...state.selectedSamples], action.filters)
      return { ...state, selectedSamples: filteredSamples, selectedSamplesCount: getTotalSelectedCount(filteredSamples), selectedChemicalsCount: getChemicalsCount(filteredSamples), selectedApecsCount: getApecsCount(filteredSamples) }
    case Actions.UPDATE_LAB_FILTERS:
      const updatedLabFilters = { ...state.labFilters, ...action.filters }
      const updatedLabOptions = { ...state.labOptions, ...sortLabInformation(filterLabs(state.labs, updatedLabFilters))}
      return { ...state, labFilters: updatedLabFilters, labOptions: updatedLabOptions}
    case Actions.SET_NUMBER_OF_COOLERS:
      return { ...state, numberOfCoolers: action.numberOfCoolers }
    case Actions.UPDATE_EMAIL_INFO:
      return { ...state, emailInfo: { subject: action.subject, body: action.body } }
    case Actions.UPDATE_HEADER_DETAILS:
      const updateLabs = addNewLab(state.labs, action.headerDetails.lab_information)
      return { ...state, headerDetails: { ...state.headerDetails, ...action.headerDetails }, labs: updateLabs, labOptions: sortLabInformation(updateLabs), loadedLabSelection: getLoadedLabOptions(action.headerDetails.lab_information)}
    default:
      return state
    case Actions.SET_LABS:
      return { ...state, labs: action.labs }
  }
}
