import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from "react-router-dom"
import { usePortfolioAPI, usePortfolioContext } from "../../../contexts/portfolioContext"
import PortfolioSideBar from "./portfolioSideBar"
import LoadingSpinner from "../../shared/loadingSpinner"
import Widget from "../../shared/widget"
import { formatWidgetOption, portfolioWidgetOptions } from "../../../utilities/widgetHelpers";

const Portfolio = () => {
  const { portfolioId } = useParams()
  const { portfolio, widget, defaultWidget } = usePortfolioContext()
  const { updateWidget } = usePortfolioAPI()
  const [widgetOptions, setWidgetOptions] = useState()

  const defaultWidgetOption = useMemo(() => defaultWidget && formatWidgetOption(defaultWidget), [defaultWidget])

  useEffect(() => {
    if (portfolio && defaultWidgetOption) portfolioWidgetOptions(portfolioId, defaultWidgetOption).then(res => setWidgetOptions(res))
  }, [portfolio, defaultWidgetOption])

  return (
    portfolio ? (
      <div className="h-full">
        <div className='pt-8 pl-8 pb-2'>
          <h1 className='text-3xl font-bold'>{portfolio.name}</h1>
          <h3>{portfolio.type}</h3>
        </div>
        <div className='grid grid-cols-12'>
          {widgetOptions &&
            <PortfolioSideBar
              defaultWidget={defaultWidgetOption}
              changeWidget={updateWidget}
              widgetOptions={widgetOptions}
              classNames={'col-span-4 lg:col-span-4 xl:col-span-3 2xl:col-span-2'}
              portfolio={portfolio}
            />
          }
          <div className='col-span-8 lg:col-span-8 xl:col-span-9 2xl:col-span-10 lg:h-full '>
            <Widget widgetProp={widget} widgetStyles="h-screen" />
          </div>
        </div>
      </div>
    ) : <LoadingSpinner />
  )
}

export default Portfolio
