import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import {
  createDayEntry,
  createNewTimeEntry,
  createUpdateAllConsumableEntries,
  deleteTimesheetTimeEntry,
  updateDayEntryAPI,
  updateTimeEntryAPI
} from "../api/timesheetsApi"
import {
  consumableEntriesApiVerifier,
  removeTimeEntryWithId,
  timeEntryApiVerifier,
  updateDayEntry,
  updateTimeEntryValue,
  updateTimeEntryWithId
} from "../utilities/timesheetHelpers"

/* Actions */
const ACTIONS = {
  INIT: "INIT",
  INIT_EDIT_ENTRY_STATE: "INIT_EDIT_ENTRY_STATE",
  TOGGLE: "TOGGLE",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_EDIT_ENTRY_STATE: "UPDATE_EDIT_ENTRY_STATE",
  UPDATE_EDIT_ENTRY_NESTED_ARRAY_STATE: "UPDATE_EDIT_ENTRY_NESTED_ARRAY_STATE",
  ADD_DAY_ENTRY: "ADD_DAY_ENTRY",
  UPDATE_DAY_ENTRY: "UPDATE_DAY_ENTRY",
  ADD_TIME_ENTRY: "ADD_TIME_ENTRY",
  UPDATE_TIME_ENTRY: "UPDATE_TIME_ENTRY",
  REMOVE_TIME_ENTRY: "REMOVE_TIME_ENTRY",
}

/* Initial States */
export const blankEditEntry = {
  id: null,
  description: "",
  portfolio_id: null,
  project_id: null,
  timesheet_task_id: null,
  day_entry_id: null,
  date: null,
  hours: 0,
  errors: false,
  selectedDay: null,
}

const initialState = {
  week: {},
  userId: null,
  editModal: false,
  editEntry: blankEditEntry,
}

const initState = (week) => ({ ...initialState, week: week, userId: week.user_id })

const initEditEntryState = (
  {
    id,
    description,
    project_id,
    portfolio_id,
    timesheet_task_id,
    day_entry_id,
    date,
    selectedDay,
    hours,
    consumable_entries,
    user_id,
  }
) => ({
  id: id,
  user_id: user_id,
  description: description || "",
  project_id: project_id,
  portfolio_id: portfolio_id,
  timesheet_task_id: timesheet_task_id,
  day_entry_id: day_entry_id || "",
  date: date || "",
  selectedDay: selectedDay || null,
  hours: hours || 0,
  errors: false,
  consumableEntries: consumable_entries || [],
})

/* Reducer */
const timeTableReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return { ...state, ...initState(action.initState) }
    case ACTIONS.INIT_EDIT_ENTRY_STATE:
      return { ...state, editEntry: initEditEntryState(action.initEntryState) }
    case ACTIONS.TOGGLE:
      return { ...state, [action.state]: !state[action.state] }
    case ACTIONS.UPDATE_TIME_ENTRY:
      const updatedWeekUpdatedTimeEntry = updateTimeEntryWithId(state.week, action.timeEntry)
      return { ...state, week: updatedWeekUpdatedTimeEntry }
    case ACTIONS.REMOVE_TIME_ENTRY:
      const updatedWeekRemoveTimeEntry = removeTimeEntryWithId(state.week, action.dayEntryId, action.timeEntryId)
      return { ...state, week: updatedWeekRemoveTimeEntry }
    case ACTIONS.ADD_DAY_ENTRY:
      const updatedWeekDayEntry = updateDayEntry(state.week, action.dayEntry)
      return { ...state, week: updatedWeekDayEntry }
    case ACTIONS.ADD_TIME_ENTRY:
      const updatedWeekTimeEntry = updateTimeEntryValue(state.week, action.dayEntryId, action.timeEntry)
      return { ...state, week: updatedWeekTimeEntry }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_EDIT_ENTRY_STATE:
      return { ...state, editEntry: { ...state.editEntry, [action.field]: action?.value } }
    case ACTIONS.UPDATE_EDIT_ENTRY_NESTED_ARRAY_STATE:
      return {
        ...state,
        editEntry: {
          ...state.editEntry,
          [action.field]: Array.isArray(state.editEntry[action.field])
            ? state.editEntry[action.field].map((obj, index) =>
              index === action.index ? { ...obj, ...action.value } : obj
            )
            : action.value,
        },
      };
    case ACTIONS.UPDATE_DAY_ENTRY:
      const updatedWeekDayEntryValue = updateDayEntry(state.week, action.dayEntry)
      return { ...state, week: updatedWeekDayEntryValue }
    default:
      return state
  }
}

/* Contexts */
const TimeTableContext = createContext(initialState)
const TimeTableApiContext = createContext({})

/* Providers */
export const TimeTableProvider = ({ children, week }) => {
  const [state, dispatch] = useReducer(timeTableReducer, week, initState)
  useEffect(() => void dispatch({ type: ACTIONS.INIT, initState: week }), [week])

  const api = useMemo(() => {
    const toggleState = (state) => dispatch({ type: ACTIONS.TOGGLE, state })

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateEditEntryState = (field, value) => dispatch({ type: ACTIONS.UPDATE_EDIT_ENTRY_STATE, field, value })

    const updatedEditEntryNestedArrayState = (field, index, value) => dispatch({
      type: ACTIONS.UPDATE_EDIT_ENTRY_NESTED_ARRAY_STATE,
      field,
      index,
      value
    })

    const addDayEntry = dayEntry => dispatch({ type: ACTIONS.ADD_DAY_ENTRY, dayEntry })

    const addTimeEntry = (dayEntryId, timeEntry) => dispatch({ type: ACTIONS.ADD_TIME_ENTRY, timeEntry, dayEntryId })

    const initEditModal = entry => {
        dispatch({
          type: ACTIONS.INIT_EDIT_ENTRY_STATE,
          initEntryState: entry
        }) || toggleState('editModal')
    }

    const resetModal = (modalType) => toggleState(modalType) || updateState('editEntry', blankEditEntry)

    const createEntries = async ({ timeEntryRef, consumableRef }) => {
      const timeEntryFormData = timeEntryApiVerifier(timeEntryRef)
      if (!timeEntryFormData) return updateEditEntryState('errors', true)

      const existingDayEntryId = timeEntryFormData.get('day_entry_id')
      const dayEntry = existingDayEntryId ? null : await createDayEntry(timeEntryFormData)

      if (dayEntry) addDayEntry(dayEntry)

      const dayEntryId = existingDayEntryId || dayEntry.id
      const timeEntry = await createNewTimeEntry(dayEntryId, timeEntryFormData)

      if (consumableRef.current) {
        const consumableData = consumableEntriesApiVerifier(consumableRef)
        const consumableEntries = await createUpdateAllConsumableEntries(dayEntryId, consumableData, timeEntry.id)
        addTimeEntry(dayEntryId, { ...timeEntry, consumable_entries: consumableEntries })
      }
      else {
        addTimeEntry(dayEntryId, timeEntry)
      }

      resetModal('editModal')
    }



    const updateEntries = async ({ timeEntryRef, consumableRef }) => {
      const timeEntryFormData = timeEntryApiVerifier(timeEntryRef)
      const consumableData = consumableEntriesApiVerifier(consumableRef)

      if (timeEntryFormData && (consumableData || !consumableRef.current)) {
        const dayEntryId = timeEntryFormData.get('day_entry_id')
        const timeEntryId = timeEntryFormData.get('id')
        if (consumableRef.current) {
          if (consumableData) {
            await createUpdateAllConsumableEntries(dayEntryId, consumableData, timeEntryId)
          }
        }
        const updatedTimeEntry = await updateTimeEntryAPI(dayEntryId, timeEntryId, timeEntryFormData)
        dispatch({ type: ACTIONS.UPDATE_TIME_ENTRY, timeEntry: updatedTimeEntry })

        resetModal('editModal')
      } else {
        updateEditEntryState('errors', true)
      }
    }

    const deleteEntries = async (dayEntryId, timeEntryId) => {
      await deleteTimesheetTimeEntry(dayEntryId, timeEntryId)
      dispatch({ type: ACTIONS.REMOVE_TIME_ENTRY, dayEntryId, timeEntryId })
      resetModal('editModal')
    }

    const updateSelectedUsers = value => updateState('selectedUsers', value)

    return {
      toggleState, updateState, updateEditEntryState, initEditModal,
      updateEntries, createEntries, deleteEntries, updateSelectedUsers, updatedEditEntryNestedArrayState
    }
  }, [])

  api.updateDayEntry = async (dayEntryId, params) => {
    updateDayEntryAPI(dayEntryId, params).then(res => dispatch({
      type: ACTIONS.UPDATE_DAY_ENTRY,
      dayEntry: res
    }))
  }

  return (
    <TimeTableApiContext.Provider value={api}>
      <TimeTableContext.Provider value={state}>
        {children}
      </TimeTableContext.Provider>
    </TimeTableApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useTimeTableContext = () => useContext(TimeTableContext)
export const useTimeTableApi = () => useContext(TimeTableApiContext)
