import React, { useEffect, useMemo } from "react";
import Select from "react-select";
import { UTM_ZONE_OPTIONS } from "../../constants/locationData";

const CoordinatesField = ({ boreholeDetails, onUseLatLonToggle, onInputChange, determineUtmZone }) => {
  const manualOverrideDisabled = useMemo(() => !boreholeDetails.selected_boreholes.some(borehole => borehole.is_utm), [boreholeDetails])
  const utmZoneButtonClickable = useMemo(() => manualOverrideDisabled && 'pointer-events-none opacity-60', [manualOverrideDisabled])
  const selectedZone = zone => onInputChange('utmZone', zone.value)

  useEffect(() => void manualOverrideDisabled && onInputChange('utmZone', ''), [manualOverrideDisabled])

  return (
    <div className='flex flex-col items-start w-1/2 gap-y-3'>
      <label className='text-lg font-medium w-2/3 flex flex-row w-full justify-between'>
        Use LAT / LON:
        <input
          style={{ transform: 'scale(2)' }}
          type="checkbox"
          className="border border-gray-400 rounded-md outline-none relative right-1.5"
          checked={boreholeDetails.onUseLatLonToggle}
          onChange={onUseLatLonToggle}
        />
      </label>
      {determineUtmZone && <label className='text-lg font-medium w-2/3 flex flex-col w-full justify-between gap-y-3'>
        Manual Override for UTM Coordinates
        <Select
          options={UTM_ZONE_OPTIONS}
          onChange={selectedZone}
          className={utmZoneButtonClickable}
          placeholder="UTM Zone"
          isDisabled={manualOverrideDisabled}
        />
      </label>}
    </div>
  )
}

export default CoordinatesField
