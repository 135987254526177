import { faSync } from "@fortawesome/free-solid-svg-icons/faSync"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useEffect, useState } from 'react'
import { useMobileRecordsApi, useMobileRecordsContext } from "../../../contexts/mobileRecordsContext"

const SyncRecordsButton = () => {
  const { awaitingJobs } = useMobileRecordsContext()
  const { syncQfieldMobileRecords } = useMobileRecordsApi()
  const [pullingNotes, setPullingNotes] = useState(false)

  const onClickHandler = useCallback(() => {
    setPullingNotes(true)
    syncQfieldMobileRecords()
  }, [syncQfieldMobileRecords, setPullingNotes])

  useEffect(() => {
    if (pullingNotes) setPullingNotes(awaitingJobs)
  }, [awaitingJobs])

  return (
    <>
      {pullingNotes && (
        <div className='flex flex-row items-center rounded-2xl px-5 py-1'>
          <FontAwesomeIcon icon={faSync} spin className='text-indigo-500' />
          <p className='ml-3 -mr-2'>Pulling Mobile Records...</p>
        </div>
      )}
      {!pullingNotes && (
        <button
          className={'btn btn-primary cursor-pointer'}
          onClick={onClickHandler}>
          <FontAwesomeIcon icon={faSync} className='mr-2' />
          Pull notes
        </button>
      )}
    </>
  )
}

export default SyncRecordsButton
