import React from 'react'
import { usePhotoAPIContext } from '../../../contexts/photoContext'

const PhotoValue = ({ entry }) => {
  const { updateOpenIndex } = usePhotoAPIContext()

  return (
    <div className='grid grid-cols-3 gap-x-1 mr-2'>
      <img
        alt={entry.value}
        src={`/mobile-record-images/${entry.id}`}
        className='cursor-pointer'
        onClick={() => updateOpenIndex(entry.value)}
      />
    </div>
  )
}

export default PhotoValue

