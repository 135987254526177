import { simpleGet } from "./apiConfig"

/**
 * Fetches active jobs based on the provided parameters
 * @param {Object} params - The parameters for fetching active jobs
 * @param {string} params.jobName - Name of the job to fetch
 * @param {string|null} [params.projectId] - Optional project ID to filter jobs
 * @param {Object} [options={}] - Additional options for the request
 * @returns {Promise} Promise that resolves with the active jobs
 * @throws {Error} If jobName is not provided or invalid
 */
export const getActiveJobs = ({ jobName, projectId = null }, options = {}) => {
  if (!jobName || typeof jobName !== 'string') throw new Error('Job name is required and must be a string')

  const searchParams = new URLSearchParams({
    job_name: jobName,
    ...(projectId && { project_id: projectId }),
    ...options
  })

  return simpleGet(`/synchronized_jobs/find_active?${searchParams.toString()}`)
}
