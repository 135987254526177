import React from "react"
import { useTimeTableApi } from "../../../../../contexts/timeTableContext"
import { inputClass } from "../../../../../utilities/stylesHelpers"
import { classNames } from "../../../../../utilities/utils"

const TimesheetModalHourInput = ({ field, value, name, errors }) => {
  const { updateEditEntryState } = useTimeTableApi()

  const onChangeHandler = event => updateEditEntryState(field, event.target.value)
  const requiredStyle = (errors, value) => (errors && !value ? "border-red-500" : "")

  return <>
    <label htmlFor={field} className="flex justify-end items-center w-full">
      <p>{name}</p>
      <input
        type='number'
        name={field}
        min="0"
        max="24"
        step="0.25"
        className={classNames(inputClass, requiredStyle(errors, value))}
        value={value}
        onChange={onChangeHandler}
      />
    </label>
  </>
}

export default TimesheetModalHourInput
