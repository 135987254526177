import React, { useEffect } from 'react';
import { useTimesheetContext } from "../../../../../contexts/timesheetContext";
import { useTimeTableApi, useTimeTableContext } from "../../../../../contexts/timeTableContext";
import { useConsumableEntry } from '../../../../../hooks/useConsumableEntry';
import Consumables from './consumables';

const ConsumablesForm = ({ consumableRef }) => {
  const { editEntry } = useTimeTableContext()
  const { tasks, consumables } = useTimesheetContext()
  const { updateEditEntryState } = useTimeTableApi()
  const { selectedConsumableEntries } = useConsumableEntry({ editEntry, tasks, consumables })
  const { timesheet_task_id, project_id } = editEntry

  useEffect(() => {
    updateEditEntryState('consumableEntries', selectedConsumableEntries)
  }, [timesheet_task_id, project_id])

  return (<>
    {selectedConsumableEntries?.length > 0 &&
      <form ref={consumableRef} className="flex flex-col gap-5">
        <h2>Consumables</h2>
        <span className="text-red-700">Quantities are Required* (use zero if not applicable)</span>
        <Consumables selectedConsumableEntries={selectedConsumableEntries} />
      </form>
    }
  </>
  )
}

export default ConsumablesForm
