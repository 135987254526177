import React from "react"
import { useUserLandingPageApi, useUserLandingPageContext } from "../../contexts/userLandingPageContext"
import DropDown from "../shared/dropdown"

const SideBar = ({ containerClasses = '' }) => {
  const { widgets } = useUserLandingPageContext()
  const { selectWidget } = useUserLandingPageApi()

  return (
    <div className={`p-8 lg:w-1/4 bg-gray-200 h-full ${containerClasses}`}>
      <div className={`w-full h-full flex-col lg:flex`}>
        <div className="flex flex-col gap-3">
          <>
            <p className='text-sm'>Widget Select</p>
            <DropDown defaultWidget={widgets[0]} options={widgets} handleChange={selectWidget} styles='mb-7' />
            <hr className='border-black mb-7' />
          </>
          <a className="btn btn-primary truncate w-full text-center" href={`/timesheets`}>
            Timesheet
          </a>
          <a className="btn btn-primary truncate w-full text-center" href={`/safety_tickets`}>
            Safety Tickets
          </a>
        </div>
      </div>
    </div>
  )
}

export default SideBar
