import React, { useEffect, useState } from 'react'
import ProjectSideBar from "./projectSideBar"
import Widget from "../../shared/widget"
import ProjectDetails from "./ProjectDetails"
import StatusSelect from "./statusSelect"
import LoadingSpinner from "../../shared/loadingSpinner"
import { statusOptions } from "../../historicalAssessment/historicalAssessmentHelper";
import SiteAdditionalFields from "./siteAdditionalFields"
import SecondarySites from "./secondarySites"
import BreadCrumbs from "../nav/breadCrumbs"
import { useProjectContext } from "../../../contexts/projectContext"

const PortfolioProject = ({ project, routes }) => {
  const [widget, setWidget] = useState("")
  const [loading, setLoading] = useState(true)
  const [projectStatus, setProjectStatus] = useState()
  const { portfolio } = useProjectContext()

  useEffect(() => {
    setProjectStatus(statusOptions.filter(option => option.value == project.status)[0])
    setLoading(false)
  }, [project])

  useEffect(() => {
    if (portfolio) {
      const newWidget = project?.default_widget?.full_url || portfolio?.default_widget?.full_url
      if (widget !== newWidget) setWidget(newWidget)
    }
  }, [project, portfolio])

  return (
    <div>
      <BreadCrumbs portfolio={project.portfolio} project={project} routes={routes} />
      <div className='pl-8 pb-5'>
        <h1 className='text-3xl font-bold'>{project?.name}</h1>
        <h3>{projectStatus && projectStatus?.label}</h3>
      </div>
      <div className='flex flex-col lg:flex-row h-full'>
        {loading && <div className="w-full py-10 flex items-center justify-center" style={{ maxHeight: '500px' }}>
          <LoadingSpinner />
        </div>}
        {!loading && (
          <>
            <ProjectSideBar project={project} routes={routes} containerClasses='md:w-1/4' />
            <div className='flex flex-col w-full md:w-3/4 p-8 pt-0'>
              <Widget widgetProp={widget} project={project} />
              <div className='flex flex-row justify-between'>
                <ProjectDetails project={project} containerStyles="m-8" />
                {project?.portfolio &&
                  <StatusSelect project={project} projectStatus={projectStatus} setProjectStatus={setProjectStatus} />}
              </div>
              <div className="flex flex-col lg:flex-row w-full">
                <SiteAdditionalFields project={project} />
                <SecondarySites project={project} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default PortfolioProject
