import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from 'react'
import { useProjectContext } from '../../../contexts/projectContext'
import BreadCrumbs from '../../projects/nav/breadCrumbs'
import SyncRecordsButton from "./syncRecordsButton"

const MobileRecordHeader = () => {
  const { portfolio, project, routes } = useProjectContext()

  return (
    <div className='flex flex-row justify-between'>
      <BreadCrumbs portfolio={portfolio} project={project} />
      <div className='actions items-center flex gap-x-2 mr-2'>
        <SyncRecordsButton />
        {routes?.boreholeLogExportPath &&
          <a className="btn btn-primary cursor-pointer" href={routes?.boreholeLogExportPath} target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
            Export Borehole Logs
          </a>}
        {routes?.exportCsvPath &&
          <a href={routes.exportCsvPath} className='btn btn-primary' target="_blank">
            <FontAwesomeIcon icon={faFilePdf} className='mr-2' />
            Export CoC
          </a>}
      </div>
    </div>
  )
}

export default MobileRecordHeader
