import React from 'react'
import { useTimeTableContext } from '../../../../../contexts/timeTableContext'

const HiddenConsumableFields = ({ consumableEntry, index }) => {
  const { editEntry } = useTimeTableContext()
  const { id } = editEntry
  return (
    <>
      <input
        type="hidden"
        name={`consumable_entries_attributes[${index}][timesheet_time_entry_id]`}
        value={id} 
        />
      <input
        type="hidden"
        name={`consumable_entries_attributes[${index}][required]`}
        value={consumableEntry?.required} 
        />
      <input
        type="hidden"
        name={`consumable_entries_attributes[${index}][id]`}
        value={consumableEntry?.id || undefined}
      />
      <input
        type="hidden"
        name={`consumable_entries_attributes[${index}][timesheet_consumable_id]`}
        value={consumableEntry.timesheet_consumable_id}
      />
      <input
        type="hidden"
        name={`consumable_entries_attributes[${index}][timesheet_task_consumable_id]`}
        value={consumableEntry.timesheet_task_consumable_id}
      />
      <input type="hidden"
        name={`consumable_entries_attributes[${index}][project_id]`}
        value={consumableEntry.project_id || undefined}
      />

    </>
  )
}

export default HiddenConsumableFields
