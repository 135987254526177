import React from 'react'
import Select from 'react-select'
import { inputClass } from '../../../utilities/stylesHelpers'
import { classNames } from '../../../utilities/utils'

const requiredStyle = (errors, value) => (errors && !value ? "border-red-500" : "")

const SelectedField = ({ label, name, options, value, onChange, required, errors, disable = false }) => (
  <label className="w-full">
    <h2>
      {label} {required && <span className="text-red-700">*</span>}
      {disable && <span className="text-red-700">* (Cannot be changed)</span>}
    </h2>
    <Select
      options={options}
      onChange={onChange}
      className={classNames(inputClass, requiredStyle(errors, value))}
      value={value}
      placeholder={label}
      name={name}
      isDisabled={disable}
      isClearable
    />
  </label>
)

export default SelectedField
