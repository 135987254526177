import React, { useEffect, useState } from "react"
import { format } from "date-fns"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { parseDateString } from "../../../utilities/dateHelpers"

const DateCell = ({ content, styler, onClickHandler, collapsed = false }) => {
  const [cellContent, setCellContent] = useState('')
  const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td

  useEffect(() => {
    const parsedDate = parseDateString(content?.value)

    if (parsedDate) {
      setCellContent(format(parsedDate, collapsed ? 'dd/MM/yyyy' : 'PPP'))
    } else {
      setCellContent(content?.value || '')
    }
  }, [content, collapsed])

  return (
    <td className={tdStyles}
        style={{ ...styler }} onClick={onClickHandler}>
      <p className={tdTextStyles}>{cellContent}</p>
      <FontAwesomeIcon
        className={tableStyles.hoverIcon}
        icon={faEdit}
      />
    </td>
  )
}

export default DateCell
