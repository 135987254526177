import React from "react";

const BoreholeHeaderField = ({ title, field, boreholeDetails, onInputChange }) => {
  return (
    <div className='flex flex-row items-center justify-between w-full'>
      <label className='text-lg font-medium w-1/2'>{title}:</label>
      <input
        className="border border-gray-400 focus:bg-white rounded-md outline-none text-black p-2 w-full"
        value={boreholeDetails[field]}
        onChange={event => onInputChange(field, event.target.value)}
      />
    </div>
  )
}

export default BoreholeHeaderField
