import React from "react";

const ProjectRow = ({ project, children }) => (
  <a
    href={`/projects/${project.id}`}
    className='block border-b border-gray-200 text-gray-600 text-base w-full p-5 font-semibold'
  >
    NAME: {project.name } - JOB NUM: {project.job_number}
    {children}
  </a>
)

export default ProjectRow
