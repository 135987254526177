import { useMemo } from "react";
import { getSelectedConsumables } from "../utilities/timesheetHelpers";

export const useConsumableEntry = ({
  editEntry,
  tasks,
  consumables,
}) => {
  const { timesheet_task_id, consumableEntries, project_id } = editEntry

  // Compute selected values

  const selectedTask = useMemo(() => tasks.find(task => task.id === timesheet_task_id), [timesheet_task_id])
  const selectedConsumables = useMemo(() => { return selectedTask ? getSelectedConsumables({ consumables, selectedTask }) : undefined }, [selectedTask])
  const selectedConsumableEntries = useMemo(() => {
    const renamedSelectedConsumables = selectedConsumables?.map(({ id, task_consumable_id, ...rest }) => ({
      timesheet_consumable_id: id,
      timesheet_task_consumable_id: task_consumable_id,
      project_id: project_id,
      ...rest
    }))

    const selectedConsumableEntries = renamedSelectedConsumables?.map(consumable => {
      const matchingEntry = consumableEntries?.find(entry => {
        return (entry.timesheet_task_consumable_id === consumable.timesheet_task_consumable_id) 
        && (entry.project_id === consumable.project_id)
        && (entry.timesheet_consumable_id === consumable.timesheet_consumable_id)
      })
    
      return matchingEntry ? { ...consumable, ...matchingEntry } : consumable;
    })  

    return selectedConsumableEntries
  }, [selectedConsumables, consumableEntries, project_id])

  return { selectedConsumableEntries }
}
