import React, { Fragment } from "react";
import { useTimeTableApi, useTimeTableContext } from "../../../../../contexts/timeTableContext";
import { inputClass } from "../../../../../utilities/stylesHelpers";
import { classNames } from "../../../../../utilities/utils";
import HiddenConsumableFields from "./hiddenConsumableFields";

const requiredStyle = (errors, value) => (errors && !value ? "border-red-500" : "")

const Consumables = ({ selectedConsumableEntries }) => {
  const { updatedEditEntryNestedArrayState } = useTimeTableApi()
  const { editEntry } = useTimeTableContext()

  const onChangeHandler = (nestedField, index, event) => updatedEditEntryNestedArrayState('consumableEntries', index, { [nestedField]: event.target.value })
  const { errors } = editEntry

  return (
    <>
      {selectedConsumableEntries.map((consumableEntry, index) => {
        const idPrefix = `consumable-${index}`
        const required = consumableEntry?.required
        const classType =(value) => required? classNames(inputClass, requiredStyle(errors, value)) : classNames(inputClass)

        return (<Fragment key={index}>
          <h2>{consumableEntry?.name}</h2>
          <div
            key={index}
            className="flex flex-col lg:flex-row gap-4 w-full justify-between"
          >
            <HiddenConsumableFields consumableEntry={consumableEntry} index={index} />
            <label htmlFor={`${idPrefix}-quantity`} className="w-full">
              Quantity:
              {required && <span className="text-red-700">*</span>}
            </label>
            <input
              id={`${idPrefix}-quantity`}
              type="number"
              value={consumableEntry?.quantity || undefined}
              className={classType(consumableEntry?.quantity)}
              name={`consumable_entries_attributes[${index}][quantity]`}
              onChange={(event) => onChangeHandler(`quantity`, index, event)}
            />
            <label htmlFor={`${idPrefix}-comment`} className="w-full">
              Comment
            </label>
            <textarea
              className="border-solid border-2 border-gray-300 rounded-lg h-full min-h-fit w-full relative p-3"
              placeholder="Enter description..."
              value={consumableEntry?.comment || undefined}
              name={`consumable_entries_attributes[${index}][comment]`}
              onChange={(event) => onChangeHandler(`comment`, index, event)}
            />
          </div>
        </Fragment>
        )
      })}
    </>
  )
}

export default Consumables;
