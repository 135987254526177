import axios from 'axios'
import React, { useEffect, useState } from 'react'
import useInterval from "../../../hooks/use_interval"
import { ImportModal } from '../../shared/importModal'
import LoadingSpinner from '../../shared/loadingSpinner'
import Onboarding from '../../shared/onboarding'
import ProjectInfo from "./projectInfo"
import ProjectRow from "./projectRow"

const Projects = ({ originalSearchTerm, currentPage, landingPageWidget }) => {
  let [projects, setProjects] = useState()
  const [loading, setLoading] = useState(false)
  const [searchName, setSearchName] = useState(originalSearchTerm);
  const [searchJobCode, setSearchJobCode] = useState('')

  const handleSearchName = (event) => {
    setSearchName(event.target.value)
  }

  const handleJobCode = (event) => {
    setSearchJobCode(event.target.value)
  }


  const handleSubmitSearchName = (event) => {
    event.preventDefault();
    loadProjectsSearchName();
  }

  const handleSubmitSearchJobCode = (event) => {
    event.preventDefault();
    loadProjectsSearchJobCode();
  }


  const syncProjects = () => {
    axios
      .post(`/sync_projects.json`)
      .then((res) => setLoading(true))
  }

  function loadProjectsSearchName() {
    axios
      .get(`/projects.json`, { params: { page: currentPage, searchName: searchName } })
      .then((res) => setProjects(res.data))
  }

  function loadProjectsSearchJobCode() {
    axios
      .get(`/projects.json`, { params: { page: currentPage, searchJobCode: searchJobCode } })
      .then((res) => setProjects(res.data))
  }

  useEffect(() => {
    getActiveJob().then((res) => setLoading(res.data !== null))
  }, [])

  useEffect(() => {
    if (!loading) {
      loadProjectsSearchName()
    }
  }, [loading])

  useInterval(async () => {
    if (loading) {
      const response = await getActiveJob()
      setLoading(response.data !== null)
    }
  },
    3000
  )

  const getActiveJob = async () => {
    return axios.get(`/synchronized_jobs/find_active.json?job_name=SyncProjectsJob`)
  }

  const SyncProjectsButton = () => {
    if (loading) {
      return (
        <ImportModal title='Pulling Projects'>
          <LoadingSpinner />
        </ImportModal>
      )
    } else {
      return (
        <button className='focus-outline:none btn btn-primary' onClick={() => syncProjects()}>
          Pull projects
        </button>
      )
    }
  }

  return (
    <>
      <div className='header'>
        <h1>Projects</h1>
        <div className='actions'>
          <SyncProjectsButton />
        </div>
      </div>

      <div className="flex flex-row items-start w-full p-5 font-semibold space-x-4">
        <div className="flex flex-col">
          <form onSubmit={handleSubmitSearchName} className="flex flex-row items-center">
            <label>
              <input type="text" value={searchName} onChange={handleSearchName} placeholder='Enter Project Name'
                className='border border-gray-400 rounded-md outline-none text-black p-2 bg-gray-100' />
            </label>
            <input type="submit" value="Submit" className='mx-1 focus-outline:none btn btn-primary' />
          </form>
        </div>
        <div className="flex flex-col">
          <form onSubmit={handleSubmitSearchJobCode} className="flex flex-row items-center">
            <label>
              <input type="number" value={searchJobCode} onChange={handleJobCode} placeholder='Enter Job Code'
                className='border border-gray-400 rounded-md outline-none text-black p-2 bg-gray-100' />
            </label>
            <input
              type="submit"
              value="Submit"
              disabled={!/^\d{6,}$/.test(searchJobCode)}
              className={`mx-1 btn btn-primary ${!/^\d{6,}$/.test(searchJobCode) ? 'opacity-50 cursor-not-allowed' : ''}`}
            />
            {!/^\d{6,}$/.test(searchJobCode) && (
              <p className="text-red-500 m-4">6 digits atleast required</p>
            )}
          </form>
        </div>
      </div>

      {!projects && <LoadingSpinner />}

      <div className='flex flex-col overflow-y-scroll'>
        {projects && projects.length > 0 &&
          <div className='flex flex-col'>
            {projects.map((project) => (
              <ProjectRow key={project.id} project={project} landingPageWidget={landingPageWidget}>
                <ProjectInfo project={project} />
              </ProjectRow>
            ))}
          </div>
        }
        {projects && projects.length === 0 && <Onboarding title='No projects found.' />}
      </div>
    </>
  )
}

export default Projects
